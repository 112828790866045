import React, { useState, useEffect } from 'react'
import { Button } from '@/components/common/Button'
import { useAuth } from '../../../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { useRouter } from 'next/router'

export function SignInWith() {
  const { googleLogin } = useAuth()
  const [isLoggingIn, setIsLoggingIn] = useState(true)
  const router = useRouter()

  useEffect(() => {
    if (router.pathname === '/login') {
      setIsLoggingIn(true)
    } else {
      setIsLoggingIn(false)
    }
  }, [router])

  return (
    <div>
      <div className="mt-1 grid">
        <Button
          type="button"
          className="flex items-center justify-center gap-2 rounded-md bg-googleBlue px-4 py-2 text-white hover:bg-googleBlue focus:outline-none focus:ring-2 focus:ring-googleBlue focus:ring-offset-2"
          onClick={() => googleLogin()}
        >
          <FontAwesomeIcon icon={faGoogle} className="h-5 w-5" />
          <span className="ml-2 font-medium">
            {isLoggingIn ? `Sign in` : `Sign up`} with Google
          </span>
        </Button>
      </div>

      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="bg-white px-2 text-gray-500">Or</span>
        </div>
      </div>
    </div>
  )
}
