import React from 'react'
import Link from 'next/link'

export function ForgotPassword() {
  return (
    <Link
      href="/reset-password"
      className="relative top-6 text-right text-sm text-indigo-600 hover:text-indigo-500"
    >
      Forgot password?
    </Link>
  )
}
