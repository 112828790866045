import Head from 'next/head'
import React, { useState, useEffect } from 'react'

import { AuthLayout } from '@/components/layouts/AuthLayout'
import { Button } from '@/components/common/Button'
import { TextField } from '@/components/common/Fields'
import { ForgotPassword } from '@/components/UI/ForgotPassword'
import { SignInWith } from '@/components/UI/SignInWith'

import { useAuth } from '../../context/AuthContext'
import { BasicErrorMessage } from '@/components/common/BasicErrorMessage'
import { useRouter } from 'next/router'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { login } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null)
      }, 5000)
    }
  }, [error])

  async function handleSubmit(e) {
    e.preventDefault()
    setError(null)

    if (!email || !password) {
      setError('Please fill in all fields')
      return
    }

    try {
      setLoading(true)

      await login(email, password).then((res) => {
        if (res) {
          router.push('/dashboard')
        }
      })
    } catch (error) {
      setError('Incorrect email or password.')
    }

    setLoading(false)
  }

  return (
    <>
      <Head>
        <title>Sign In - OpenSpeech</title>
      </Head>
      <AuthLayout>
        <h2 className="text-2xl font-bold tracking-tight text-gray-800">
          Sign in to OpenSpeech
        </h2>
        <div className="mt-8">
          <SignInWith />
          {error && <BasicErrorMessage error={error} />}
          <form className="mt-4 grid grid-cols-1">
            <TextField
              label="Email address"
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="flex flex-col">
              <ForgotPassword />
              <TextField
                label="Password"
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <Button
                type="submit"
                variant="solid"
                color="indigo"
                className="mt-8 px-14 font-bold"
                loading={loading}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  )
}
